import React from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import './App.css';


const Redirector = () => {
  return <div className='item'>
    <h2>Wellcome to Qworkingo</h2>
    <p>Please download our app for accessing the space 24//7 for your platform.</p>

    <div className="item">
      <a href="https://apps.apple.com/mk/app/qworkingo-coworking-space/id1594147432">
        <img src="/appstore.png" alt="android-logo" />
      </a>
    </div>

    <div className="item">
      <a href="https://play.google.com/store/apps/details?id=dev.qodix.qoas">
        <img src="/playstore.png" alt="android-logo" />
      </a>
    </div>

    <div className="item">
      <a href="https://appgallery.huawei.com/app/C105320889">
        <img src="/huawei.png" alt="huawei-logo" />
      </a>
    </div>
  </div>;
}


const router = createBrowserRouter([
  {
    path: "/",
    element: <Redirector />
  },
  {
    path: "/register",
    element: <Redirector />
  },
  {
    path: "/finish-registration",
    element: <Redirector />
  },
  {
    path: "/forgot-password/:token",
    element: <Redirector />
  },
]);

const App = () => {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
